
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SearchPage',
	data() {
		const searchText = decodeURIComponent(location.hash.substring(1));
		return {
			searchText: searchText,
			searchResults: [],
		};
	},
	created: async function() {
		this.searchResults = await this.search();
		(this.$refs.searchText as HTMLInputElement).focus()
	},
	methods: {
		search: async function() {
			if (this.searchText.length <= 2) {
				return [];
			}
			const res = await fetch(`/api/search?q=${this.searchText}`);
			return await res.json();
		}
	},
	watch: {
		searchText: async function() {
			location.hash = await this.searchText;
			this.searchResults = await this.search();
		}
	},
});

