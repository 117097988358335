import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bb243da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "searchBox" }
const _hoisted_2 = { id: "searchResults" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        id: "searchBar",
        class: _normalizeClass({ wiggle: _ctx.searchText.length === 0 })
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "searchText",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchTextChanged && _ctx.searchTextChanged(...args))),
          type: "text",
          placeholder: "Søgeord...",
          autocomplete: "off"
        }, null, 544), [
          [_vModelText, _ctx.searchText]
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (item) => {
        return (_openBlock(), _createElementBlock("ul", {
          class: "result",
          key: item.Danish
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (translations, language) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(language.toLowerCase()),
              key: language
            }, _toDisplayString(translations.join(', ')), 3))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}